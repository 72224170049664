import { CircularProgress } from "@mui/material";
import { format, formatRelative, isWithinInterval, sub } from "date-fns";
import { Star, StarBorder } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ChatMessageList } from "../components/ChatMessageList";
import { RootState, useAppDispatch } from "../redux/store";
import { Chat, chatsActions } from "../redux/reducers/chats";
import { Dropdown } from "../components/Dropdown";
import { ChevronRightIcon } from "../components/ChevronRightIcon";
import { ChevronDownIcon } from "../components/ChevronDownIcon";
import { LinkIcon } from "../components/LinkIcon";
import { CheckIcon } from "../components/CheckIcon";

const formatDate = (dateString: string) => {
  // Create a new date object with the current date and time
  const now = new Date();

  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Format the date using the format string and log the result
  if (isWithinInterval(date, { start: sub(now, { days: 2 }), end: now })) {
    const formattedDate = formatRelative(date, now);

    return formattedDate.substring(0, 1).toUpperCase() + formattedDate.substring(1);
  }

  return format(date, "MM/dd/yyyy 'at' p")
};

const sortAndFilterChats = (chats: Chat[], filter: string): Chat[] => {
  return chats.filter((chat) => {
    if (filter === "starred") {
      return chat.favorite;
    }

    if (filter === "hallucinations") {
      return chat.messages.some((message) => message.hallucination);
    }

    return true;
  }).sort((a, b) => {
    const aDate = new Date(a.createdAt || "");
    const bDate = new Date(b.createdAt || "");

    return aDate.getTime() > bDate.getTime() ? -1 : 1;
  });
};

export const ChatLogs = () => {
  const isLoading = useSelector((state: RootState) => state.chats.isLoading);
  const chatsMap = useSelector((state: RootState) => state.chats.entities);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedChatId, setSelectedChatId] = useState<string>(searchParams.get("id") || "");
  const [filter, setFilter] = useState<string>(searchParams.get("filter") || "all");

  useEffect(() => {
    dispatch(chatsActions.get());
  }, []);

  const onSelectChat = (chatId: string) => {
    setSearchParams({ id: chatId, filter });
    setSelectedChatId(chatId);
  };

  const onClickChatId = () => {
    navigator.clipboard.writeText(`${window.origin}/chatlogs?id=${selectedChatId}`);
  };

  const onClickFavoriteHandler = (chatId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    dispatch(chatsActions.updateById(chatId, { favorite: !chatsMap[chatId]?.favorite }));
  };

  const onClickThumbsDownHandler = (chatId: string) => (messageId: string) => {
    // @ts-ignore
    dispatch(chatsActions.recordHallucination(chatId, messageId, true));
  };

  const onClickThumbsUpHandler = (chatId: string) => (messageId: string) => {
    // @ts-ignore
    dispatch(chatsActions.recordHallucination(chatId, messageId, false));
  };

  const onClickFilterHandler = (filter: string) => () => {
    setTimeout(() => {
      setSearchParams({ id: selectedChatId, filter });
      setFilter(filter);
    }, 100);
  };

  return (
    <div className="flex flex-grow items-center justify-center h-full bg-white overflow-hidden">
      <div className="h-full w-full">
        <div className="grid-cols-3 flex flex-row items-center justify-start cursor-pointer p-2 border-b-[1px] border-gray-200 bg-gray-50">
          <Dropdown
            items={[
              <div className="flex flex-row items-center" onClick={onClickFilterHandler("all")}>
                <CheckIcon className={clsx("mr-3", filter === "all" ? "text-blue-500" : "opacity-0")} />
                <span className={filter === "all" ? "font-medium" : ""}>All</span>
              </div>,
              <div className="flex flex-row items-center" onClick={onClickFilterHandler("starred")}>
                <CheckIcon className={clsx("mr-3", filter === "starred" ? "text-blue-500" : "opacity-0")} />
                <span className={filter === "starred" ? "font-medium" : ""}>Starred</span>
              </div>,
              <div className="flex flex-row items-center" onClick={onClickFilterHandler("hallucinations")}>
                <CheckIcon className={clsx("mr-3", filter === "hallucinations" ? "text-blue-500" : "opacity-0")} />
                <span className={filter === "hallucinations" ? "font-medium" : ""}>Hallucinations</span>
              </div>,
            ]}
          >
            <div className="flex flex-row items-center my-3 px-4 border-r-[1px] border-gray-200">
              <span className="mr-2 font-medium text-gray-900 text-base">{filter.substring(0, 1).toLocaleUpperCase()}{filter.substring(1)}</span>
              <ChevronDownIcon className="w-3" />
            </div>
          </Dropdown>
        </div>
        <ul className="h-full w-full overflow-y-scroll scrollbar">
          {isLoading ? (
            <div className="h-full w-full flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : sortAndFilterChats(Object.values(chatsMap) as Chat[], filter).map((chat) => (
            <li
              key={chat.id}
              className={clsx(
                "grid grid-cols-[32px_192px_1fr_32px] items-center cursor-pointer px-6 py-2 hover:bg-gray-100 border-b-[1px]",
                chat.id === selectedChatId && "bg-gray-200",
              )}
              onClick={() => onSelectChat(chat.id)}
            >
              <button className="flex items-center justify-center" onClick={onClickFavoriteHandler(chat.id)}>
                {chat.favorite ? (
                  <Star className="mr-4" style={{ fill: "#EAB308", height: 20, width: 20 }} />
                ) : (
                  <StarBorder className="mr-4" style={{ height: 20, width: 20 }}  />
                )}
              </button>
              <span className="mr-4 text-sm text-gray-900 whitespace-nowrap">{formatDate(chat.createdAt)}</span>
              <span className="mr-4 text-gray-500 whitespace-nowrap truncate">{chat.messages[chat.messages.length - 1]?.content}</span>
              <ChevronRightIcon className="h-3 justify-self-end" />
            </li>
          ))}
        </ul>
      </div>
      {chatsMap[selectedChatId] && (
        <div className="h-full flex flex-col w-[400px] min-w-[400px] justify-start border-l-[1px] border-gray-200">
          <div className="flex flex-row p-6 items-center bg-gray-100 cursor-pointer" onClick={onClickChatId}>
            <div className="flex flex-row items-center cursor-pointer active:opacity-60 text-xl font-medium" onClick={onClickChatId}>
              <span className="text-gray-900 mr-2">Chat ID:</span>
              <span className="text-gray-600 mr-2">{selectedChatId.substring(0, 8)}</span>
              <LinkIcon />
            </div>
          </div>
          <ChatMessageList
            messages={chatsMap[selectedChatId]?.messages || []}
            onClickThumbsDown={onClickThumbsDownHandler(selectedChatId)}
            onClickThumbsUp={onClickThumbsUpHandler(selectedChatId)}
          />
        </div>
      )}
    </div>
  );
};
