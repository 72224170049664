export type EventHandler = (...args: any[]) => void;

export class EventManager {
  private hooks: Record<string, EventHandler[]> = {};

  on = (eventName: string, handler: EventHandler): void => {
    if (!this.hooks[eventName]) {
      this.hooks[eventName] = [];
    }

    this.hooks[eventName]?.push(handler);
  };

  off = (eventName: string, handler: EventHandler): void => {
    const eventHandlers = this.hooks[eventName];
    if (eventHandlers) {
      this.hooks[eventName] = eventHandlers.filter((h) => h !== handler);
    }
  };

  fire = (eventName: string, ...args: any[]): void => {
    const eventHandlers = this.hooks[eventName];
    if (eventHandlers) {
      eventHandlers.forEach((handler) => handler(...args));
    }
  };
}
