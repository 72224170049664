import { getRestActions } from "../utils/RestActions";
import { RestEntity, getRestSlice } from "../utils/RestSlice";

export interface Task extends RestEntity {
  id: string;
  name: string;
  prompt: string;
  temperature: number;
  status: string;
  configurationId: string;
  chatIds: string[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export const tasksSlice = getRestSlice<Task>("tasks");
export const tasksActions = getRestActions<Task>("tasks", tasksSlice);
