import { CircularProgress, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { PlayArrow, Stop } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import React, { ChangeEvent, useEffect, useState } from "react";

import { RootState, useAppDispatch } from "../redux/store";
import { Task, tasksActions } from "../redux/reducers/tasks";
import { getSocket } from "../sdk/socket";
import { configurationsActions } from "../redux/reducers/configurations";
import { PlusIcon } from "../components/PlusIcon";
import { CheckIcon } from "../components/CheckIcon";
import { CloseIcon } from "../components/CloseIcon";
import { PauseIcon } from "../components/PauseIcon";
import { ChevronRightIcon } from "../components/ChevronRightIcon";
import { Button } from "../components/Button";

type FormData = {
  name: string;
  prompt: string;
  temperature: number;
  configurationId: string;
  chatIds: string[];
};

const defaultFormData = {
  name: "",
  prompt: "",
  temperature: 0.5,
  configurationId: "",
  chatIds: [],
};

const sortTasks = (tasks: Task[]): Task[] => {
  return tasks.sort((a, b) => {
    const aDate = new Date(a.createdAt || "");
    const bDate = new Date(b.createdAt || "");

    return aDate.getTime() > bDate.getTime() ? 1 : -1;
  });
};

export const Tasks = () => {
  const isLoading = useSelector((state: RootState) => state.tasks.isLoading);
  const configurationIds = useSelector((state: RootState) => state.configurations.ids);
  const configurationsMap = useSelector((state: RootState) => state.configurations.entities);
  const taskIds = useSelector((state: RootState) => state.tasks.ids);
  const tasksMap = useSelector((state: RootState) => state.tasks.entities);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTaskId, setSelectedTaskId] = useState<EntityId>(searchParams.get("id") || "");
  const [formData, setFormData] = useState<FormData>(defaultFormData);

  useEffect(() => {
    dispatch(configurationsActions.get());
    dispatch(tasksActions.get());

    const onConnect = () => {
      console.log("connected");
    };

    const taskUpdateById = (data: Task) => {
      dispatch(tasksActions.updateById(data.id, data, { remote: false }));
    };

    getSocket().on("connect", onConnect);
    getSocket().on("task.update", taskUpdateById);

    return () => {
      getSocket().off("connect", onConnect);
      getSocket().off("task.update", taskUpdateById);
    };
  }, []);

  useEffect(() => {
    if (selectedTaskId === "new") {
      setFormData(defaultFormData);
    } else {
      setFormData(tasksMap[selectedTaskId] || defaultFormData);
    }
  }, [selectedTaskId, tasksMap]);

  const onSelectTask = (id: EntityId) => {
    setSearchParams({ id: id.toString() });
    setSelectedTaskId(id);
  };

  const onChangeFormDataHandler = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [key]: event.target.value,
    });
  };

  const onClickSaveTask = () => {
    if (selectedTaskId === "new") {
      dispatch(tasksActions.create(formData));
    } else {
      dispatch(tasksActions.updateById(selectedTaskId, formData));
    }
  };

  const onClickStartTaskHandler = (id: EntityId) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    getSocket().emit("task.start", {
      taskId: id,
    });
  };

  const onClickStopTaskHandler = (id: EntityId) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    getSocket().emit("task.stop", {
      taskId: id,
    });
  };

  return (
    <div className="h-full flex flex-row bg-white overflow-hidden">
      <div className="h-full w-full">
        <div className="grid grid-cols-[224px_224px_1fr_64px_64px_64px_48px] items-center justify-start cursor-pointer py-5 px-6 border-b-[1px] border-gray-200 bg-gray-50">
            <span className="text-gray-900 whitespace-nowrap truncate">Name</span>
            <span className="text-gray-900 whitespace-nowrap truncate">Configuration</span>
            <span className="text-gray-900 whitespace-nowrap truncate">Prompt</span>
            <span className="text-gray-900 whitespace-nowrap truncate">Chats</span>
            <span className="text-gray-900 whitespace-nowrap truncate">Status</span>
            <span className="text-gray-900 whitespace-nowrap truncate"></span>
            <div
              className="text-blue-500 flex items-center justify-center rounded-full border-blue-500 border-2 w-5 h-5 justify-self-end"
              onClick={() => onSelectTask("new")}
            >
              <PlusIcon width="12" height="12"/>
            </div>
        </div>
        <ul className="flex flex-col h-full w-full overflow-y-scroll scrollbar">
          {isLoading ? (
            <div className="h-full w-full flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : sortTasks(taskIds.map((id) => tasksMap[id]) as Task[]).map((task) => (
            <li
              key={task.id}
              className={clsx(
                "grid grid-cols-[224px_224px_1fr_64px_64px_64px_48px] items-center cursor-pointer px-6 py-2 border-b-[1px] border-gray-200 hover:bg-gray-100",
                task.id === selectedTaskId && "bg-gray-200",
              )}
              onClick={() => onSelectTask(task.id)}
            >
              <span className="text-gray-900 whitespace-nowrap truncate">{task.name}</span>
              <span className="text-gray-500 whitespace-nowrap truncate">{configurationsMap[task.configurationId || ""]?.name}</span>
              <span className="text-gray-500 whitespace-nowrap truncate">{task.prompt}</span>
              <span className="text-gray-500 whitespace-nowrap truncate ml-4">{task.chatIds.length}</span>
              <span className="text-gray-500 whitespace-nowrap truncate ml-4">
                <div
                  className={clsx(
                    "border-2 w-5 h-5 rounded-full flex items-center justify-center",
                    task.status === "succeeded" && "border-green-500",
                    task.status === "failed" && "border-red-500",
                    task.status === "in-progress" && "border-yellow-500",
                  )}
                >
                  {task.status === "succeeded" && (
                    <CheckIcon
                      className="text-green-500"
                      width="10px"
                      height="10px"
                    />
                  )}
                  {task.status === "failed" && (
                    <CloseIcon
                      className="text-red-500"
                      width="8px"
                      height="8px"
                    />
                  )}
                  {task.status === "in-progress" && (
                    <PauseIcon
                      className="text-yellow-500"
                      width="10px"
                      height="10px"
                    />
                  )}
                </div>
              </span>
              <span className="text-gray-500 whitespace-nowrap truncate ml-4">
                  {task.status === "in-progress" ? (
                    <button onClick={onClickStopTaskHandler(task.id)}>
                      <Stop />
                    </button>
                  ) : (
                    <button onClick={onClickStartTaskHandler(task.id)}>
                      <PlayArrow />
                    </button>
                  )}
              </span>
              <ChevronRightIcon className="h-3 justify-self-end" />
            </li>
          ))}
        </ul>
      </div>
      {(tasksMap[selectedTaskId] || selectedTaskId === "new") && (
        <div className="h-full flex flex-col w-[400px] min-w-[400px] justify-start border-l-[1px] border-gray-200">
          <div className="flex flex-row p-6 items-center justify-between bg-gray-100 cursor-pointer">
            <span className="cursor-pointer active:opacity-60 text-xl font-medium text-gray-900 mr-2">
              {selectedTaskId === "new" ? "New Task" : tasksMap[selectedTaskId]?.name}
            </span>
            <CloseIcon onClick={() => onSelectTask("")} />
          </div>
          <div className="flex flex-col flex-grow overflow-hidden p-4">
            <Select
              value={formData.configurationId}
              onChange={onChangeFormDataHandler("configurationId") as (event: SelectChangeEvent<string>) => void}
              style={{ marginBottom: "16px" }}
            >
              {configurationIds.map((id) => (
                <MenuItem key={id} value={id}>
                  {configurationsMap[id]?.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Name"
              value={formData.name}
              onChange={onChangeFormDataHandler("name")}
              style={{ marginBottom: "16px" }}
            />
            <TextField
              label="Prompt"
              value={formData.prompt}
              onChange={onChangeFormDataHandler("prompt")}
              style={{ marginBottom: "16px" }}
              multiline
            />
            <TextField
              label="Temperature (0 - 1)"
              value={formData.temperature}
              onChange={onChangeFormDataHandler("temperature")}
              style={{ marginBottom: "16px" }}
              multiline
            />
            {!!formData.chatIds?.length && (
              <>
                <span>Chats:</span>
                <ul>
                  {formData.chatIds.map((chatId) => (
                    <li key={chatId} className="flex flex-row items-center cursor-pointer text-blue-700" onClick={() => navigate(`/chatlogs?id=${chatId}`)}>
                      {chatId}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className="border-t-[1px] border-gray-200 p-4">
            <Button className="w-full" onClick={onClickSaveTask} isLoading={isLoading}>
              {selectedTaskId === "new" ? "Create" : "Update"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
