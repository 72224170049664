import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

import { CONCIERGE_SERVICE_URL, COOKIE_KEY_CHAT_ID, COOKIE_KEY_CONFIGURATION_ID } from "../config";
import { PoshieSdk } from "../sdk";
import { Chat } from "../redux/reducers/chats";
import { Configuration } from "../redux/reducers/configurations";

const url = new URL(window.location.href);
const configurationIdUrlParam = url.searchParams.get("configurationid");
const chatIdUrlParam = url.searchParams.get("chatid");
const configurationIdCookie = Cookies.get(COOKIE_KEY_CONFIGURATION_ID) || "";
const chatIdCookie = Cookies.get(`${configurationIdCookie}:${COOKIE_KEY_CHAT_ID}`) || "";

const sdk = new PoshieSdk({
  apiUrl: CONCIERGE_SERVICE_URL,
  configurationId: configurationIdUrlParam || configurationIdCookie,
  chatId: chatIdUrlParam || chatIdCookie,
});

export const PoshieSdkContext = React.createContext({
  sdk,
  chatId: sdk.chatId,
  configurationId: sdk.configurationId,
  chat: sdk.chat,
  retry: 0,
  isInitialized: false,
  isLoading: false,
});

export type PoshieSdkProviderProps = {
  children?: React.ReactNode;
};

export const PoshieSdkProvider: React.FC<PoshieSdkProviderProps> = ({
  children,
}) => {
  const [chat, setChat] = useState<Chat>(sdk.chat);
  const [chatId, setChatId] = useState<string>(sdk.chatId || "");
  const [retry, setRetry] = useState<number>(sdk.retry || 0);
  const [configurationId, setConfigurationId] = useState<string>(sdk.configurationId || "");
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialize = async () => {
    try {
      const response = await fetch(`${CONCIERGE_SERVICE_URL}/v1/configurations/${sdk.configurationId}`);
      const responseJson = await response.json() as { data: Configuration };

      if (responseJson.data.enabled) {
        window.parent.postMessage({ type: "widget.initialize" }, "*");
      }
    } catch (error) {
      console.log("Widget not enabled");
    }
  };

  useEffect(() => {
    initialize();

    const initializedHandler = () => {
      setIsInitialized(true);
    };

    const loadingHandler = (newIsLoading: boolean) => {
      setIsLoading(newIsLoading);
    };

    const chatChangeHandler = () => {
      setChat(sdk.chat);
    };

    const chatRetryHandler = () => {
      setRetry(sdk.retry);
    };

    const chatIdChangeHandler = () => {
      const newChatId = sdk.chatId || "";

      setChatId(newChatId);
      Cookies.set(`${configurationId}:${COOKIE_KEY_CHAT_ID}`, newChatId);
    };

    const configurationIdChangeHandler = () => {
      const newConfigurationId = sdk.configurationId || "";

      setConfigurationId(newConfigurationId);
      Cookies.set(COOKIE_KEY_CONFIGURATION_ID, newConfigurationId);
    };

    sdk.on("initialized", initializedHandler);
    sdk.on("loading", loadingHandler);
    sdk.on("chat.change", chatChangeHandler);
    sdk.on("chat.retry", chatRetryHandler);
    sdk.on("chat_id.change", chatIdChangeHandler);
    sdk.on("configuration_id.change", configurationIdChangeHandler);

    return () => {
      sdk.off("initialized", initializedHandler);
      sdk.off("loading", loadingHandler);
      sdk.off("chat.change", chatChangeHandler);
      sdk.off("chat.retry", chatRetryHandler);
      sdk.off("chat_id.change", chatIdChangeHandler);
      sdk.off("configuration_id.change", configurationIdChangeHandler);
    };
  }, [])

  return (
    <PoshieSdkContext.Provider value={{ sdk, chatId, configurationId, chat, retry, isInitialized, isLoading }}>
      {children}
    </PoshieSdkContext.Provider>
  );
};
