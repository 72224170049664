import clsx from "clsx";
import React from "react";

import { ErrorFilledIcon } from "./ErrorFilledIcon";

type Props = {
  isDisabled?: boolean;
  isError?: boolean;
  help?: string;
  label: string;
  isLabelDisplayed?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  requirementType?: "required" | "optional";
  rootClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, React.PropsWithChildren<Props>>((props: React.PropsWithChildren<Props>, ref) => {
  const { help, children, className, isDisabled, isError, isLabelDisplayed = true, placeholder, requirementType, rootClassName, label, ...rest } = props;

  return (
    <div className={clsx("flex", rootClassName)}>
      <div className="flex-auto">
        <label className="flex flex-col">
          <div
            className={clsx(
              "flex w-0 min-w-full flex-row-reverse items-end justify-between gap-2 break-words font-medium",
              (label || requirementType) && "mb-1",
            )}
          >
            <p className="text-sm capitalize text-gray-500">{requirementType}</p>
            <p className={clsx(!isLabelDisplayed && "sr-only")}>{label}</p>
          </div>
          <div className="relative flex items-center">
            {isError && <ErrorFilledIcon className="absolute right-0 mr-2 flex text-red-600" data-testid="errorIcon"></ErrorFilledIcon>}
            <input
              className={clsx(
                "w-full rounded-md border indent-3 leading-10 outline-none focus:ring-1",
                isError ? "border-red-600 pr-8 ring-red-600 placeholder:text-red-600" : "ring-gray-400 focus:border-gray-400",
                className,
              )}
              data-testid="input"
              disabled={isDisabled}
              placeholder={placeholder}
              type="text"
              {...rest}
              ref={ref}
            ></input>
            {children}
          </div>
        </label>
        {help && <p className={clsx("mt-2 w-0 min-w-full break-words text-sm", isError ? "text-red-600" : "text-gray-500")}>{help}</p>}
      </div>
    </div>
  );
});

Input.displayName = "Input";
