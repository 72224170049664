import { EntityState } from "@reduxjs/toolkit";
import { UpdateByIdResponse, getRestActions, makeRequest } from "../utils/RestActions";
import { RestEntity, getRestSlice } from "../utils/RestSlice";
import { AppDispatch } from "../store";

export type Role = "assistant" | "user" | "system";

export type Participant = {
  id: string;
  role: Role;
}

export type Message = {
  id: string;
  content: string;
  type: "text" | "file";
  participant: Participant;
  hallucination: boolean | null;
  createdAt: string;
};

export interface Chat extends RestEntity {
  id: string;
  configurationId: string;
  favorite: boolean;
  messages: Message[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  metadata: Record<string, unknown>;
};

export const chatsSlice = getRestSlice<Chat>("chats", (adapter) => ({
  // @ts-ignore
  recordHallucination: (state, action) => {
    adapter.updateOne(state as EntityState<Chat>, action.payload);
    state.isLoadingWrite = false;
  },
}));

export const chatsActions = getRestActions<Chat>("chats", chatsSlice, {
  recordHallucination: (chatId: string, messageId: string, hallucination: boolean) => async (dispatch: AppDispatch) => {
    dispatch(chatsSlice.actions.isLoadingWrite());

    const response = await makeRequest<UpdateByIdResponse<Chat>>(`/v1/chats/${chatId}/messages/${messageId}`, {
      method: "PATCH",
      body: {
        hallucination,
      },
    });

    // @ts-ignore
    dispatch(chatsSlice.actions.recordHallucination(response.data));
  },
});
