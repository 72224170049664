import { Link, Outlet, useLocation } from "react-router-dom";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const MENU_ITEMS = [
  {
    label: "Configurations",
    to: "/settings/configurations",
  },
  {
    label: "Personas",
    to: "/settings/personas",
  },
  {
    label: "Actions",
    to: "/settings/actions",
  },
  {
    label: "Preview",
    to: "/settings/preview",
  },
];

export const SettingsLayout = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();

  const onClickSidebarLinkHandler = (index: number) => () => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const index = MENU_ITEMS.findIndex((item) => item.to === location.pathname);

    setCurrentIndex(index);
  }, [location]);

  return (
    <div className="flex flex-row w-full h-full overflow-hidden">
      <div className="flex flex-col w-[200px] min-w-[200px] h-full border-r">
        {MENU_ITEMS.map((item, index) => (
          <Link
            key={item.to}
            className={clsx(
              "flex flex-row items-center h-12 cursor-pointer p-4",
              currentIndex === index && "bg-gray-200",
            )}
            onClick={onClickSidebarLinkHandler(index)}
            to={item.to}
          >
            {item.label}
          </Link>
        ))}
      </div>
      <div className="flex flex-grow bg-white">
        <Outlet />
      </div>
    </div>
  );
};
