import React from "react";

export const ThumbsUpIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M6.88709 2.82666C5.89486 5.34336 4.99958 4.8991 4.99958 5.74928C4.99958 6.15066 5.32204 6.49795 5.75279 6.49795C5.9129 6.49795 6.07976 6.44158 6.21957 6.33109C8.61901 4.41649 8.03267 2.49966 9.06099 2.49966C9.56615 2.49966 9.74881 2.89656 9.74881 3.14686C9.74881 3.3814 9.51429 4.38266 8.9505 5.38395C8.88736 5.50121 8.85804 5.62523 8.85804 5.74928C8.85804 6.18902 9.21438 6.47315 9.60674 6.47315H13.9095C14.2365 6.50246 14.5003 6.76407 14.5003 7.09332C14.5003 7.39774 14.259 7.65483 13.9501 7.67964C13.5532 7.70219 13.2487 8.03369 13.2487 8.42608C13.2487 8.90642 13.6096 8.92446 13.6096 9.32587C13.6096 10.1197 12.509 9.71375 12.509 10.6474C12.509 10.9969 12.7143 11.0533 12.7143 11.3397C12.7143 12.05 11.7807 11.7727 11.7807 12.6138C11.7807 12.7514 11.8168 12.7987 11.8168 12.9092C11.8168 13.2317 11.5506 13.5001 11.2214 13.5001H9.57968C8.78137 13.5001 7.98757 13.2362 7.34485 12.7559L6.19928 11.8922C6.06623 11.7907 5.90839 11.7456 5.75279 11.7456C5.31753 11.7456 4.99958 12.1019 4.99958 12.4988C4.99958 12.7243 5.10105 12.9453 5.29949 13.0942L6.44507 13.9511C7.34485 14.6254 8.45666 14.9997 9.58419 14.9997H11.2214C12.3309 14.9997 13.2352 14.1405 13.3141 13.0558C13.8666 12.6725 14.2139 12.041 14.2139 11.3397C14.2139 11.2472 14.2049 11.1502 14.1959 11.0578C14.7484 10.6744 15.1092 10.0385 15.1092 9.32587C15.1092 9.16123 15.0866 8.99887 15.0483 8.84328C15.6211 8.43961 16 7.81719 16 7.09332C16 5.9387 15.0618 5.00283 13.9095 5.00283H10.7884C11.0839 4.22256 11.2507 3.54376 11.2507 3.14686C11.2507 2.09374 10.4321 1 9.06099 1C7.60423 1 7.2073 2.0103 6.88709 2.82666ZM0.999004 5.97027C0.448739 5.97027 0 6.41903 0 6.93996V13.915C0 14.4698 0.448739 14.8847 0.999004 14.8847H3.00152C3.54953 14.8847 3.99832 14.4359 3.99832 13.915V6.96701C3.99832 6.44609 3.5563 5.97027 3.00152 5.97027H0.999004Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
