import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { ChatConsole } from './ChatConsole';
import { PoshieSdkContext } from '../context/PoshieSdkProvider';
import { CloseIcon } from './CloseIcon';
import { Button } from './Button';
import { Input } from './Input';

type WidgetProps = {
  className?: string;
  onClickClose?: () => void;
};

export const Widget = ({
  className = "",
  onClickClose = () => {},
}: WidgetProps) => {
  const { sdk, chat, chatId, configurationId, isLoading } = useContext(PoshieSdkContext);

  useEffect(() => {
    window.parent.postMessage({ type: "chat_id.change", configurationId, chatId }, "*");
  }, [chatId]);

  const onMessageSend = async (message: string) => {
    sdk.sendMessage(message);
  };

  return (
    <div className={clsx(
      "relative flex items-end w-full h-full overflow-hidden shadow-lg",
      className,
    )}>
      <div className="relative flex flex-col w-full h-full z-10">
        <div className="flex flex-row items-center justify-between bg-blue-500 p-4">
          <div className="flex flex-row items-center">
            <img
              alt="Poshie"
              src="/logo.png"
              className="w-10 h-10 mr-2"
            />
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-white" style={{ lineHeight: "1.5rem" }}>Poshie</span>
              <span className="text-white" style={{ lineHeight: "1rem" }}>AI Assistant</span>
            </div>
          </div>
          <CloseIcon
            color="white"
            className="h-4 w-4 cursor-pointer"
            onClick={onClickClose}
          />
        </div>
        <ChatConsole
          messages={chat?.messages}
          onMessageSend={onMessageSend}
          isLoading={isLoading}
        />
      </div>
      <div className={clsx(
        "absolute flex flex-col justify-between top-[10%] w-full h-[calc(80%+2px)] border-blue-500 border-2 bg-white rounded-l-lg shadow-lg transition-all z-0 p-4",
        false ? "left-[calc(-100%+2px)]" : "left-0",
      )}>
        <span className="text-xl font-semibold text-center">Membership Application</span>
        <div>
          <Input className="mb-4" label="Full Name" />
          <Input className="mb-4" label="Email" />
          <Input className="mb-4" label="Phone" />
          <Input className="mb-4" label="Address" />
        </div>
        <Button className="w-full" onClick={() => {}}>
          Submit
        </Button>
      </div>
    </div>
  );
};
