import clsx from "clsx";
import React from "react";

type Props = {
  isPaused?: boolean;
} & React.SVGAttributes<SVGSVGElement>;

export const Loader = (props: Props) => {
  const { isPaused, className, ...rest } = props;

  return (
    <span aria-atomic="true" aria-live="assertive" className={clsx("block animate-spin", isPaused ? "pause" : "", className)} data-testid="loader">
      <span className="sr-only">Loading...</span>
      <svg
        aria-hidden="true"
        className="h-full w-full"
        color="#1896FC"
        fill="none"
        height="24"
        viewBox="0 0 400 400"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <defs>
          <linearGradient id="spinner-topRight">
            <stop offset="0%" stopColor="currentColor" stopOpacity="0" />
            <stop offset="100%" stopColor="currentColor" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="spinner-topLeft">
            <stop offset="0%" stopColor="currentColor" stopOpacity="0.666666666" />
            <stop offset="100%" stopColor="currentColor" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="spinner-bottomLeft">
            <stop offset="0%" stopColor="currentColor" stopOpacity="0.666666666" />
            <stop offset="100%" stopColor="currentColor" stopOpacity="0.333333333" />
          </linearGradient>
          <linearGradient id="spinner-bottomRight">
            <stop offset="0%" stopColor="currentColor" stopOpacity="0.333333333" />
            <stop offset="100%" stopColor="currentColor" stopOpacity="0" />
          </linearGradient>
        </defs>

        <g strokeWidth="24">
          <path d="M 200 100 A 100 100 270 0 1 300 200" stroke="url(#spinner-topRight)" />
          <path d="M 100 200 A 100 100 180 0 1 200 100" stroke="url(#spinner-topLeft)" />
          <path d="M 200 300 A 100 100 90 0 1 100 200" stroke="url(#spinner-bottomLeft)" />
          <path d="M 300 200 A 100 100 0 0 1 200 300" stroke="url(#spinner-bottomRight)" />
        </g>
      </svg>
    </span>
  );
};
