import { ChatOutlined, ChecklistOutlined, HomeOutlined, SettingsOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { RootState, useAppDispatch } from "../redux/store";
import { configurationsActions } from "../redux/reducers/configurations";
import { CONCIERGE_SERVICE_URL } from "../config";
import { setSession } from "../redux/reducers/session";
import { Dropdown } from "../components/Dropdown";

const MENU_ITEMS = [
  {
    icon: <HomeOutlined />,
    to: "/",
  },
  {
    icon: <ChecklistOutlined />,
    to: "/tasks",
  },
  {
    icon: <ChatOutlined />,
    to: "/chatlogs",
  },
  {
    icon: <SettingsOutlined />,
    to: "/settings",
  },
];

export const Layout = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitializedAnimationDone, setIsInitializedAnimationDone] = useState(false);
  const configurationIds = useSelector((state: RootState) => state.configurations.ids);
  const session = useSelector((state: RootState) => state.session.data);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loginUrl = `${CONCIERGE_SERVICE_URL}/login?redirectUrl=${window.location.href}`;

  const onClickSidebarLinkHandler = (index: number) => () => {
    setCurrentIndex(index);
  };

  const onClickLogout = async () => {
    try {
      const response = await fetch(`${CONCIERGE_SERVICE_URL}/logout`, { credentials: "include" });

      if (response.status === 200) {
        window.location.href = loginUrl;
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      window.location.href = loginUrl;
    }
  };

  const getSession = async () => {
    try {
      const response = await fetch(`${CONCIERGE_SERVICE_URL}/session`, { credentials: "include" });

      if (response.status === 200) {
        setIsAuthenticated(true);

        const responseJson = await response.json();

        dispatch(setSession(responseJson.data));
        dispatch(configurationsActions.get());
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      window.location.href = loginUrl;
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    const index = MENU_ITEMS.findIndex((item) => {
      if (item.to === "/") {
        return item.to === location.pathname;
      }

      return location.pathname.includes(item.to);
    });

    setCurrentIndex(index);
  }, [location])

  useEffect(() => {
    if (configurationIds.length > 0) {
      setTimeout(() => {
        setIsInitializedAnimationDone(true);
      }, 1000);
    }
  }, [configurationIds]);

  return isAuthenticated ? (
    <div className="flex h-screen bg-gray-100">
      {configurationIds.length > 0 && (
        <>
          <div className="relative flex flex-col items-center justify-start bg-white w-16 min-w-[64px] shadow-md border-r-gray-200 border-r-1">
            <div className={`absolute w-10 h-10 rounded-full bg-[#c7e6fe] z-0 transition-all`} style={{ top: 80 + (56 * currentIndex) }} />
            <Link to="/" onClick={onClickSidebarLinkHandler(0)}>
              <img src="/logo.png" alt="logo" className="my-4 w-10 h-10 active:scale-95" />
            </Link>
            {MENU_ITEMS.map((item, index) => (
              <Link
              key={item.to}
              className="w-full flex items-center justify-center py-4 z-10"
              onClick={onClickSidebarLinkHandler(index)}
              to={item.to}
              >
                {React.cloneElement(item.icon, {
                  size: "large",
                  className: "text-gray-700 active:scale-95",
                })}
              </Link>
            ))}
            {session?.picture && (
              <div className="flex flex-grow items-end">
                <Dropdown
                  className="z-50 cursor-pointer"
                  itemsContainerClassName="bottom-[12px] left-[50px]"
                  items={[
                    <button className="flex flex-row items-center" onClick={onClickLogout}>
                      Logout
                    </button>
                  ]}
                >
                  <img
                    src={session.picture}
                    alt="profile"
                    className="w-8 h-8 rounded-full my-4 active:scale-95"
                  />
                </Dropdown>
              </div>
            )}
          </div>
          <div className="flex-1 h-full bg-gray-100 overflow-hidden">
            <Outlet />
          </div>
        </>
      )}
      {!isInitializedAnimationDone && (
        <div className={clsx(
          "absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 z-50 transition-opacity duration-1000",
          configurationIds.length > 0 ? "opacity-0" : "opacity-100",
        )}>
          <div className="flex flex-col items-center">
            <img src="/logo.png" alt="logo" className="w-24 h-24 mb-8" />
            <CircularProgress color="info" />
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className={clsx(
      "absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 z-50 transition-opacity duration-1000",
      configurationIds.length > 0 ? "opacity-0" : "opacity-100",
    )}>
      <div className="flex flex-col items-center">
        <img src="/logo.png" alt="logo" className="w-24 h-24 mb-8" />
        <CircularProgress color="info" />
      </div>
    </div>
  );
};
