import React from "react";

export const DownloadIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M7.25043 0.747354V9.18961L4.25428 6.21999C3.96021 5.92592 3.48479 5.92592 3.19072 6.21999C2.90108 6.50966 2.90108 6.98506 3.19072 7.27915L7.46712 11.5025C7.61747 11.6772 7.80767 11.7501 8.00224 11.7501C8.19239 11.7501 8.38253 11.6772 8.53067 11.529L12.7805 7.27915C13.0747 6.98506 13.0747 6.51408 12.7805 6.21999C12.4865 5.92592 12.0111 5.92592 11.7214 6.21999L8.74741 9.18961V0.747354C8.74741 0.336102 8.41791 0 8.00224 0C7.5843 0 7.25043 0.336102 7.25043 0.747354ZM12.3295 9.50137L10.8303 10.9983H13.9989C14.2753 10.9983 14.5008 11.2261 14.5008 11.5025V13.9989C14.5008 14.2753 14.2753 14.4986 13.9989 14.4986H2.00114C1.72471 14.4986 1.49697 14.2753 1.49697 13.9989V11.5025C1.49697 11.2261 1.72471 10.9983 2.00114 10.9983H5.17191L3.67057 9.50137H2.00114C0.895519 9.50137 0 10.3925 0 11.5025V13.9989C0 15.1045 0.895519 16 2.00114 16H13.9989C15.1067 16 16 15.1045 16 13.9989V11.5025C16 10.3969 15.1023 9.50137 13.9989 9.50137H12.3295ZM12.0022 12.7518C12.0022 13.1631 12.3383 13.5014 12.7496 13.5014C13.1653 13.5014 13.5014 13.1675 13.5014 12.7518C13.5014 12.3339 13.1653 12 12.7496 12C12.3383 12 12.0022 12.3339 12.0022 12.7518Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
