import { EntityAdapter, EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export type AdditionalReducers<T> = (adapter: EntityAdapter<T>) => { [key: string]: unknown };

export type RestEntity = {
  id: string;
  [key: string]: unknown;
};

export const getRestSlice = <T extends RestEntity>(name: string, additionalReducers: AdditionalReducers<T> = () => ({})) => {
  const adapter = createEntityAdapter<T>();

  return createSlice({
    name,
    initialState: adapter.getInitialState({
      isLoading: false,
      isLoadingDelete: false,
      isLoadingWrite: false,
    }),
    reducers: {
      ...additionalReducers(adapter),
      addOne(state, action) {
        adapter.addOne(state as EntityState<T>, action.payload);
        state.isLoadingWrite = false;
      },
      isLoading(state) {
        state.isLoading = true;
      },
      isLoadingDelete(state) {
        state.isLoadingDelete = true;
      },
      isLoadingWrite(state) {
        state.isLoadingWrite = true;
      },
      setAll(state, action) {
        adapter.setAll(state as EntityState<T>, action.payload);
        state.isLoading = false;
      },
      updateOne(state, action) {
        adapter.updateOne(state as EntityState<T>, action.payload);
        state.isLoadingWrite = false;
      },
      removeOne(state, action) {
        adapter.removeOne(state as EntityState<T>, action.payload);
        state.isLoadingDelete = false;
      },
    },
  });
}

export type RestSlice<T extends RestEntity> = ReturnType<typeof getRestSlice<T>>;
