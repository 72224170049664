import React from "react";

import { actionsActions } from "../redux/reducers/actions";
import { SettingEditor } from "../components/SettingEditor";

export const SettingsActions = () => {
  return (
    <SettingEditor
      fields={[
        {
          name: "name",
          type: "text",
        },
        {
          name: "type",
          type: "text",
        },
        {
          name: "parameters",
          type: "text",
        },
        [
          {
            name: "instructions",
            type: "code",
          },
          {
            name: "resolution",
            type: "javascript",
          },
        ],
      ]}
      entityName="action"
      actions={actionsActions}
    />
  );
}
