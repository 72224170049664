export const COOKIE_KEY_CHAT_ID = "poshie_chat_id";
export const COOKIE_KEY_CONFIGURATION_ID = "poshie_configuration_id";

const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c?.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c?.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// the graphql endpoint differs based on the url that's serving the application
let backend = "";
const tempBackends = getCookie("backends");

switch (true) {
  // Cloudfront populated cookies to dynamically set the environment.
  case tempBackends !== "": {
    backend = tempBackends;
    break;
  }
  case window.origin.includes("localhost"): {
    backend = "http://localhost:8080";
    break;
  }
  case window.origin.includes("poshie.posh.ai"): {
    backend = "https://api.poshdevelopment.com/concierge";
    break;
  }
  case window.origin.includes("poshie-staging.posh.build"): {
    backend = "https://staging.poshdevelopment.com/concierge";
    break;
  }
  case window.origin.includes("poshie-dev.posh.build"): {
    backend = "https://development.posh.build/concierge";
    break;
  }
  default: {
    backend = "https://api.poshdevelopment.com/concierge";
    break;
  }
}

export const CONCIERGE_SERVICE_URL = backend;
