import { Socket, io } from 'socket.io-client';

import { CONCIERGE_SERVICE_URL } from '../config';

const url = new URL(CONCIERGE_SERVICE_URL);
const origin = url.origin;
const path = url.pathname !== "/" ? `${url.pathname}/socket.io` : undefined;

let socket: Socket;

export const getSocket = () => {
  socket = socket || io(origin, { path });

  return socket;
};
