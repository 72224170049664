import React from "react";

export const ThumbsDownIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M9.57924 1C8.45427 1 7.34284 1.36974 6.44781 2.04158L5.298 2.90053C5.10413 3.04932 4.99817 3.27027 4.99817 3.50021C4.99817 3.897 5.32054 4.2487 5.75117 4.2487C5.90672 4.2487 6.06452 4.20136 6.19753 4.09992L7.34734 3.23871C7.98307 2.76302 8.77664 2.49473 9.57924 2.49473H11.2182C11.5451 2.49473 11.8134 2.76302 11.8134 3.0899C11.8134 3.19588 11.7774 3.24322 11.7774 3.38524C11.7774 4.22616 12.7085 3.94434 12.7085 4.65451C12.7085 4.94084 12.5056 4.99494 12.5056 5.34664C12.5056 5.78401 12.8482 6.03424 13.1188 6.08609C13.4006 6.13569 13.6035 6.38143 13.6035 6.66551C13.6035 7.07357 13.2495 7.08258 13.2495 7.5718C13.2495 7.95957 13.5494 8.29096 13.9461 8.31804C14.255 8.31353 14.4985 8.59532 14.4985 8.87263C14.4985 9.19954 14.2325 9.4678 13.9033 9.4678H9.60404C9.21629 9.4678 8.85555 9.77667 8.85555 10.187C8.85555 10.311 8.88711 10.4417 8.94797 10.5522C9.51161 11.6141 9.74606 12.6151 9.74606 12.845C9.74606 13.0953 9.5612 13.4966 9.05849 13.4966C8.66618 13.4966 8.61208 13.4695 8.27844 12.6196C7.5074 10.6537 6.35082 9.49485 5.75117 9.49485C5.31603 9.49485 4.99817 9.85106 4.99817 10.2479C4.99817 10.4643 5.09511 10.6852 5.27996 10.834C7.43977 12.5587 6.60336 14.9958 9.05849 14.9958C10.4292 14.9958 11.2453 13.9024 11.2453 12.845C11.2453 12.4482 11.0807 11.7471 10.7854 10.9896H13.9033C15.0306 10.9896 16 10.054 16 8.87263C16 8.17599 15.6212 7.52897 15.0441 7.15472C15.0802 6.99915 15.1027 6.83233 15.1027 6.66551C15.1027 5.95534 14.7442 5.31959 14.1852 4.94084C14.1987 4.84387 14.2077 4.75145 14.2077 4.65451C14.2077 3.95787 13.8627 3.32438 13.3082 2.94337C13.2292 1.85895 12.3252 1 11.2182 1H9.57924ZM1.00098 1.02708C0.448665 1.02708 0 1.47572 0 1.99649V8.96957C0 9.5219 0.448665 9.94349 1.00098 9.94349H3.00073C3.55304 9.99309 4.0017 9.55121 4.0017 8.99662V1.99649C4.0017 1.44641 3.55304 1.02708 3.00073 1.02708H1.00098Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
