import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { CONCIERGE_SERVICE_URL } from "../config";
import { Widget } from "../components/Widget";
import { RootState } from "../redux/store";
import { PoshieSdkContext } from "../context/PoshieSdkProvider";
import { WidgetButton } from "../components/WidgetButton";
import { Button } from "../components/Button";
import { Input } from "../components/Input";

export const SettingsPreview = () => {
  const { sdk, configurationId } = useContext(PoshieSdkContext);
  const configurationIds = useSelector((state: RootState) => state.configurations.ids);
  const configurationsMap = useSelector((state: RootState) => state.configurations.entities);
  const [url, setUrl] = useState<string>("https://posh.ai");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const onSelectConfiguration = (event: SelectChangeEvent<string>) => {
    sdk.setConfigurationId(event.target.value);
  };

  const onClickRefresh = () => {
    sdk.createChat();
  };

  const onClickWidgetButton = () => {
    setIsOpen(!isOpen);
  };

  const onClickWidgetClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative flex flex-col w-full h-full">
      <div className="flex flex-row items-center w-full p-2 h-[64px]">
        <div className="flex-grow">
          <Input
            label=""
            placeholder="Enter URL"
            value={url}
            onChange={onChangeUrl}
            className="h-[42px] w-full mr-2 flex-grow border-gray-400 hover:border-gray-900 focus:border-blue-500 ring-blue-500"
          />
        </div>
        <Select value={configurationId || ""} onChange={onSelectConfiguration} sx={{ height: 42, width: 400, marginRight: 2 }}>
          {configurationIds.map((id) => (
            <MenuItem key={id} value={id}>
              {configurationsMap[id]?.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          className="h-[42px] mr-2"
          onClick={onClickRefresh}
        >
          Refresh
        </Button>
      </div>
      <div className="absolute w-[133.3%] scale-75 top-[64px] origin-top-left" style={{ height: "calc(133.3% - 84px)"}}>
        <iframe
          title="preview"
          src={`${CONCIERGE_SERVICE_URL}/v1/preview?url=${encodeURIComponent(url)}`}
          className="w-full h-full"
        />
        <div className="absolute bottom-6 right-6 flex flex-col h-full items-end justify-end">
          {isOpen && (
            <Widget
              onClickClose={onClickWidgetClose}
              className="!w-[400px] transition-all duration-300 rounded-lg max-h-[700px]"
            />
          )}
          <WidgetButton
            onClick={onClickWidgetButton}
            isOpen={isOpen}
          />
        </div>
      </div>
    </div>
  );
}
