import { getRestActions } from "../utils/RestActions";
import { RestEntity, getRestSlice } from "../utils/RestSlice";

export interface Persona extends RestEntity {
  id: string;
  name: string;
  personality: string;
  greeting: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export const personasSlice = getRestSlice<Persona>("personas");
export const personasActions = getRestActions<Persona>("personas", personasSlice);
