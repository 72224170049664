import React, { useEffect, useMemo, useRef } from "react";

import { ChatMessage } from "./ChatMessage";
import { Message } from "../redux/reducers/chats";

type ChatMessageProps = {
  messages: Message[];
  isLoading?: boolean;
  mode?: string;
  retry?: number;
  onClickThumbsDown?: (id: string) => void;
  onClickThumbsUp?: (id: string) => void;
};

const sortMessages = (messages: Message[]): Message[] => {
  return [...messages].sort((a, b) => {
    const aDate = new Date(a.createdAt || "");
    const bDate = new Date(b.createdAt || "");

    return aDate.getTime() > bDate.getTime() ? 1 : -1;
  });
};

export const ChatMessageList = ({
  messages = [],
  isLoading = false,
  mode = "default",
  retry = 0,
  onClickThumbsDown = () => {},
  onClickThumbsUp = () => {},
}: ChatMessageProps) => {
  const messageContainer = useRef<HTMLDivElement>(null);
  const sortedMessages = useMemo(() => sortMessages(messages), [messages]);

  useEffect(() => {
    setTimeout(() => {
      messageContainer.current?.scrollTo({
        behavior: "smooth",
        top: messageContainer.current?.scrollHeight,
      });
    }, 10);
  }, [messages]);

  const onClickThumbsDownHandler = (id: string) => () => {
    onClickThumbsDown(id);
  };

  const onClickThumbsUpHandler = (id: string) => () => {
    onClickThumbsUp(id);
  };

  return (
    <div className="flex flex-col flex-grow h-full p-4 overflow-y-scroll scrollbar" ref={messageContainer}>
      {sortedMessages.map((message, index) => (
        <ChatMessage
          key={index}
          role={message.participant?.role}
          content={message.content}
          type={message.type}
          mode="default"
          timestamp={message.createdAt}
          hallucination={message.hallucination}
          onClickThumbsDown={onClickThumbsDownHandler(message.id)}
          onClickThumbsUp={onClickThumbsUpHandler(message.id)}
          multipleMessages={sortedMessages[index + 1]?.participant?.role === message.participant?.role}
        />
      ))}
      {isLoading && (
        <ChatMessage
          key="loading"
          role="assistant"
          content="..."
          type="text"
          mode={mode}
          retry={retry}
          hallucination={false}
          isLoading
        />
      )}
    </div>
  );
};
