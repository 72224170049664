import React from "react";

import { personasActions } from "../redux/reducers/personas";
import { SettingEditor } from "../components/SettingEditor";

export const SettingsPeronas = () => {
  return (
    <SettingEditor
      fields={[
        {
          name: "name",
          type: "text",
        },
        [
          {
            label: "Default Knowledge",
            name: "knowledge",
            type: "code",
          },
          {
            name: "personality",
            type: "code",
          },
          {
            name: "greeting",
            type: "code",
          },
          {
            name: "summarizer",
            type: "code",
          },
          {
            name: "response",
            type: "code",
          },
          {
            name: "unsure",
            type: "code",
          },
        ],
      ]}
      entityName="persona"
      actions={personasActions}
    />
  );
}
