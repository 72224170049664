import React, { useEffect } from 'react';
import clsx from 'clsx';
import { RefreshOutlined } from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { RootState, useAppDispatch } from '../redux/store';
import { useSelector } from 'react-redux';
import { configurationsActions } from '../redux/reducers/configurations';
import { Button } from './Button';

type ChatMetadataProps = {
  values: Record<string, string>;
  className?: string;
  information?: string;
  configurationId: string;
  onClickRefresh?: () => void;
  onChangeConfigurationId?: (value: string) => void;
}

export const ChatMetadata = ({
  values,
  className,
  information,
  configurationId,
  onClickRefresh = () => {},
  onChangeConfigurationId = () => {},
}: ChatMetadataProps) => {
  const configurationIds = useSelector((state: RootState) => state.configurations.ids);
  const configurationsMap = useSelector((state: RootState) => state.configurations.entities);
  const dispatch = useAppDispatch();

  const onSelectConfiguration = (event: SelectChangeEvent<string>) => {
    onChangeConfigurationId(event.target.value);
  };

  useEffect(() => {
    dispatch(configurationsActions.get());
  }, []);

  return (
    <div className={clsx("flex flex-col items-start justify-between w-full p-4 bg-white mb-4 border border-gray-200 rounded-lg", className)}>
      <div className="flex flex-row items-center w-full mb-2">
        <Select value={configurationId} onChange={onSelectConfiguration} sx={{ height: 42, width: 400, marginRight: 2 }}>
          {configurationIds.map((id) => (
            <MenuItem key={id} value={id}>
              {configurationsMap[id]?.name}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={onClickRefresh} iconLeft={<RefreshOutlined />}>
          Refresh
        </Button>
      </div>
      <div>
        <table>
          <tbody>
            {Object.keys(values).map((key) => (
              <tr key={key}>
                <td className="text-gray-600 font-semibold">{key}:</td>
                <td className="w-4" />
                <td className="text-gray-600">{values[key] as string}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table>
          <tbody>
            {information ? (
              <>
                <tr>
                  <td className="text-gray-600 font-semibold">Instructions:</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td className="text-gray-600">{information}</td>
                  <td />
                  <td />
                </tr>
              </>
            ) : (
              <tr>
                <td className="text-gray-600 font-semibold">Instructions:</td>
                <td className="w-4" />
                <td className="text-gray-600">Unknown</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
