import clsx from "clsx";
import React from "react";
import { CloseIcon } from "./CloseIcon";

type WidgetButtonProps = {
  className?: string;
  isOpen?: boolean;
  onClick?: () => void;
};

export const WidgetButton = ({
  className = "",
  isOpen,
  onClick = () => {},
}: WidgetButtonProps) => {
  return (
    <div
      className={clsx(
        "relative w-14 h-14 min-w-[56px] min-h-[56px] flex items-center justify-center rounded-full bg-blue-500 border-2 border-blue-500 cursor-pointer mt-2 shadow-lg overflow-hidden",
        className,
      )}
      onClick={onClick}
    >
      <img
        alt="Poshie"
        src="/logo.png"
        className={clsx(
          "absolute top-0 left-0 w-full h-full transition-all duration-300",
          isOpen ? "opacity-0" : "opacity-100",
        )}
      />
      <CloseIcon
        color="white"
        className={clsx(
          "absolute w-4 h-4 transition-all duration-300",
          isOpen ? "opacity-100 rotate-0" : "opacity-0 rotate-45",
        )}
      />
    </div>
  );
};
