import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { configurationsActions } from "../redux/reducers/configurations";
import { SettingEditor } from "../components/SettingEditor";
import { Button } from "../components/Button";

export const SettingsConfigurations = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickPreview = () => {
    navigate(`/preview?id=${searchParams.get("id")}`);
  };

  return (
    <SettingEditor
      fields={[
        {
          name: "name",
          type: "text",
        },
        {
          name: "url",
          type: "text",
        },
        [
          {
            name: "data",
            type: "json",
          },
          {
            name: "knowledge",
            type: "code",
          },
        ],
      ]}
      entityName="configuration"
      actions={configurationsActions}
      additionalButtons={
        <div className="flex-grow justify-start h-full">
          <Button
            className="w-32 mr-2 h-full"
            variant="border"
            onClick={onClickPreview}
            color="blue"
          >
            Preview
          </Button>
        </div>
      }
    />
  );
}
