import { v4 as uuidv4 } from "uuid";

import { Chat } from "../redux/reducers/chats";
import { EventHandler, EventManager } from "../utils/EventManager";
import { Socket } from "socket.io-client";
import { getSocket } from "./socket";

const WEBSOCKET_ENABLED = true;

const EMPTY_CHAT = {
  id: "",
  configurationId: "",
  favorite: false,
  messages: [],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  deletedAt: "",
  metadata: {},
};

export type RequestOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: Record<string, unknown>;
};

export type RequestMetadata = {
  information?: string;
  retry?: number;
}

export type ChatMessageData = {
  data: Chat;
  metadata: RequestMetadata;
};

export type PoshieSdkOptions = {
  apiUrl: string;
  configurationId?: string;
  chatId?: string;
  hooks?: Record<string, Function>;
};

export class PoshieSdk {
  apiUrl: string;
  chatId: string | null;
  configurationId: string | null;
  chat: Chat;
  retry: number;
  eventManager: EventManager;
  socket?: Socket;
  isInitialized: boolean;

  constructor(options: PoshieSdkOptions) {
    this.apiUrl = options.apiUrl;
    this.chatId = options.chatId || null;
    this.configurationId = options.configurationId || null;
    this.chat = EMPTY_CHAT;
    this.retry = 0;
    this.eventManager = new EventManager();
    this.isInitialized = false;

    if (WEBSOCKET_ENABLED) {
      this.socket = getSocket();

      this.socket.on("chat:retry", (messageData: ChatMessageData) => {
        this.retry = messageData.metadata.retry || 0;
        this.eventManager.fire("chat.retry", this.retry);
      });

      this.socket.on("chat:message", (messageData: ChatMessageData) => {
        this.chatId = messageData.data.id;
        this.chat = { ...messageData.data, metadata: messageData.metadata };
        this.retry = 0;
        this.eventManager.fire("loading", false);
        this.eventManager.fire("chat_id.change", this.chatId);
        this.eventManager.fire("chat.change", this.chat);
        this.eventManager.fire("chat.retry", this.retry);
      });
    }

    this.initialize();
  }

  private initialize = async (): Promise<void> => {
    await this.sleep(2500);

    try {
      if (this.chatId) {
        await this.loadChat();
      } else {
        await this.createChat();
      }
    } catch (error) {
      await this.createChat();
    }

    this.isInitialized = true;
    this.eventManager.fire("initialized", this.isInitialized);
  };

  private sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  private makeRequest = async <T>(endpoint: string, options: RequestOptions = {}): Promise<T> => {
    const apiPromise = fetch(`${this.apiUrl}${endpoint}`, {
      ...options,
      body: options.body ? JSON.stringify(options.body) : null,
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      credentials: "include",
    });

    // Ensure that the API request waits at least 1000ms before resolving
    // to allow time for the typing animation to show always.
    const responses = await Promise.all([apiPromise, this.sleep(1000)]);
    const apiResponse = responses[0];

    if (!apiResponse.ok) {
      throw new Error(apiResponse.statusText);
    }

    return apiResponse.json() as T;
  };

  on = (eventName: string, handler: EventHandler): void => {
    this.eventManager.on(eventName, handler);
  };

  off = (eventName: string, handler: EventHandler): void => {
    this.eventManager.off(eventName, handler);
  };

  loadChat = async (): Promise<void> => {
    this.eventManager.fire("loading", true);

    const response = await this.makeRequest<{ data: Chat }>(`/v1/chats/${this.chatId}`);

    this.chat = response.data;
    this.eventManager.fire("chat.change", this.chat);
    this.eventManager.fire("loading", false);
  };

  createChat = async (): Promise<void> => {
    this.chatId = "";
    this.chat = EMPTY_CHAT;

    this.eventManager.fire("chat_id.change", this.chatId);
    this.eventManager.fire("chat.change", this.chat);
    this.eventManager.fire("loading", true);

    console.log("starting chat")

    if (this.socket) {
      this.socket.emit("chat:begin", {
        configurationId: this.configurationId,
      });
    } else {
      const response = await this.makeRequest<{ data: Chat, metadata: RequestMetadata }>("/v1/chats", {
        method: "POST",
        body: {
          configuration_id: this.configurationId,
        },
      });

      this.chatId = response.data.id;
      this.chat = response.data;

      this.eventManager.fire("chat_id.change", this.chatId);
      this.eventManager.fire("chat.change", this.chat);
      this.eventManager.fire("loading", false);
    }
  };

  sendMessage = async (message: string): Promise<void> => {
    const chat = { ...this.chat };
    if (!chat) {
      throw new Error(`Chat with id ${this.chatId} does not exist`);
    }

    chat.messages = [...(chat.messages || []), {
      id: uuidv4(),
      content: message,
      type: "text",
      participant: {
        id: uuidv4(),
        role: "user",
      },
      hallucination: null,
      createdAt: new Date().toISOString(),
    }];

    this.chat = { ...chat };
    this.eventManager.fire("chat.change", this.chat);
    this.eventManager.fire("loading", true);

    if (this.socket) {
      this.socket.emit("chat:message", {
        chatId: this.chatId,
        message,
      });
    } else {
      const response = await this.makeRequest<{ data: Chat, metadata: RequestMetadata }>(`/v1/chats/${this.chatId}/messages`, {
        method: "POST",
        body: {
          message,
        },
      });

      this.chat = response.data;
      this.eventManager.fire("chat.change", this.chat);
      this.eventManager.fire("loading", false);
    }
  };

  setConfigurationId = async (id: string): Promise<void> => {
    this.configurationId = id;
    this.eventManager.fire("configuration_id.change", this.configurationId);

    await this.createChat();
  };
}
