export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeTrailingNewlines = (str: string) => {
  return str.replace(/[\r\n]+$/, '');
};

export const titleCase = (str: string) => {
  const parts = str.split(/[\s_-]+/);

  return parts.map(capitalize).join(' ');
};
