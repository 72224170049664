import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../redux/store";
import { configurationsActions } from "../redux/reducers/configurations";

export const Preview = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const configurationId = searchParams.get("id") || "";
  const configurationsMap = useSelector((state: RootState) => state.configurations.entities);
  const url = useMemo(() => configurationsMap[configurationId]?.url, [configurationsMap, configurationId]);

  useEffect(() => {
    dispatch(configurationsActions.getById(configurationId));

    return () => {
      const elements = document.querySelectorAll("[id=poshie-widget-container]");

      elements.forEach((element) => {
        element.remove();
      });
    }
  }, []);

  useEffect(() => {
    const widgetContainer = document.getElementById("poshie-widget-container");
    if (!widgetContainer) {
      const script = document.createElement("script");

      script.setAttribute("src", `${window.origin}/poshie-entry.js`);
      script.setAttribute("configurationid", searchParams.get("id") || "");

      document.body.appendChild(script);
    }
  }, [url]);

  return (
    <div className="w-full h-full flex items-center justify-center">
      {configurationId && url ? (
        <img
          src={`https://api.microlink.io/?url=${url}&screenshot=true&meta=false&embed=screenshot.url`}
          alt={url}
          className="w-full h-full object-cover object-top"
        />
      ) : (
        <div className="text-2xl font-semibold text-gray-500">
          No configuration selected or url not present
        </div>
      )}
    </div>
  );
};
