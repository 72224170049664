import { EntityId } from "@reduxjs/toolkit";
import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { ChangeEvent, useEffect, useState } from "react";

import { RootState, useAppDispatch } from "../redux/store";
import { getRestActions } from "../redux/utils/RestActions";
import { RestEntity } from "../redux/utils/RestSlice";
import { CodeBlock } from "./CodeBlock";
import { titleCase } from "../utils/strings";
import { Button } from "./Button";
import { Toggle } from "./Toggle";

interface Field {
  label?: string;
  name: string;
  type: string;
};

type SettingEditorProps<T extends RestEntity> = {
  fields: (Field | Field[])[];
  entityName: string;
  actions: ReturnType<typeof getRestActions<T>>;
  additionalButtons?: React.ReactNode;
};

const getEntityFromState = (entityName: string, state: RootState) => {
  if (entityName === "action") {
    return state.actions;
  }

  if (entityName === "chat") {
    return state.chats;
  }

  if (entityName === "configuration") {
    return state.configurations;
  }

  if (entityName === "persona") {
    return state.personas;
  }

  if (entityName === "task") {
    return state.tasks;
  }

  throw new Error(`Unknown entity name: ${entityName}`);
};

export const SettingEditor = <T extends RestEntity>({
  fields,
  entityName,
  actions,
  additionalButtons,
}: SettingEditorProps<T>) => {
  const isLoadingDelete = useSelector((state: RootState) => getEntityFromState(entityName, state).isLoadingDelete);
  const isLoadingWrite = useSelector((state: RootState) => getEntityFromState(entityName, state).isLoadingWrite);
  const itemIds = useSelector((state: RootState) => getEntityFromState(entityName, state).ids);
  const itemsMap = useSelector((state: RootState) => getEntityFromState(entityName, state).entities);
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [itemId, setItemId] = useState<EntityId>(searchParams.get("id") || "");
  const [formData, setFormData] = useState<Record<string, unknown>>({});

  const onClickSave = async () => {
    if (itemId === "new") {
      const item = await dispatch(actions.create(formData as T));
      setItemId(item.id);
    } else {
      dispatch(actions.updateById(itemId, formData as T));
    }
  };

  const onClickDelete = () => {
    dispatch(actions.deleteById(itemId));
    setItemId("");
  };

  const onSelectItem = (event: SelectChangeEvent<string>) => {
    setItemId(event.target.value);
  };

  const onChangeFormDataHandler = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [key]: event.target.value,
    });
  };

  const onChangeEnabled = (enabled: boolean) => {
    setFormData({
      ...formData,
      enabled,
    });
  };

  const onClickTabHandler = (index: number) => () => {
    setSelectedTab(index);
  };

  useEffect(() => {
    dispatch(actions.get());
  }, []);

  useEffect(() => {
    const commandS = (event: KeyboardEvent) => {
      if (event.key === "s" && (navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey)) {
        event.preventDefault();

        onClickSave();
      }
    };

    window.addEventListener("keydown", commandS);

    return () => {
      window.removeEventListener("keydown", commandS);
    };
  }, [itemId, formData]);

  useEffect(() => {
    if (itemId === "new") {
      setFormData({});
      setSearchParams({});
    } else if (itemId) {
      setFormData(itemsMap[itemId] || {});
      setSearchParams({ id: itemId.toString() });
    } else {
      setItemId(itemIds[0] || "");
      setSearchParams({});
    }
  }, [itemId, itemIds, itemsMap, setSearchParams]);

  return (
    <div className="flex flex-col gap-4 w-full h-full p-4">
      <div className="flex flex-row gap-4 justify-between">
        <div className="flex flex-row h-full items-center">
          <Select value={itemId.toString()} onChange={onSelectItem} sx={{ width: 400 }}>
            {itemIds.map((id) => (
              <MenuItem key={id} value={id}>
                {itemsMap[id]?.name as string}
              </MenuItem>
            ))}
            <MenuItem key="new" value="new" className="flex items-center">
              + New {titleCase(entityName)}
            </MenuItem>
          </Select>
          {formData?.enabled !== undefined && (
            <Toggle
              className="ml-2"
              options={["ON", "OFF"]}
              value={formData.enabled as boolean}
              onToggle={onChangeEnabled}
            />
          )}
        </div>
        <div className="flex flex-row flex-grow h-full">
          {additionalButtons}
          {itemId !== "new" && (
            <Button
              className="w-32 mr-2"
              color="red"
              isLoading={isLoadingDelete}
              onClick={onClickDelete}
            >
              Delete
            </Button>
          )}
          <Button
            className="w-32"
            isLoading={isLoadingWrite}
            onClick={onClickSave}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2 flex-grow">
        {fields.map((field) => {
          const renderField = (field: Field) => {
            if (field.type === "text") {
              return (
                  <TextField
                    key={field.name}
                    label={field.label || titleCase(field.name)}
                    value={formData[field.name] || ""}
                    onChange={onChangeFormDataHandler(field.name)}
                    style={{ marginBottom: 8 }}
                  />
              );
            }

            return (
              <CodeBlock
                key={field.name}
                mode={field.type === "code" ? "text" : field.type}
                title={field.label || titleCase(field.name)}
                value={(formData[field.name] || "") as string}
                onChange={onChangeFormDataHandler(field.name)}
              />
            );
          };

          if (field instanceof Array) {
            const selectedField = field[selectedTab];

            return (
              <div className="flex flex-col flex-grow" key={`group-${field[0]?.name}`}>
                <div className="flex flex-row mb-4 border-b-[1px]">
                  {field.map((field, index) => (
                    <div
                      key={field.name}
                      className={clsx(
                        "flex flex-row pb-4 px-4 cursor-pointer",
                        selectedField === field && "border-b-[1px] border-blue-500"
                       )}
                       onClick={onClickTabHandler(index)}>
                      {field.label || titleCase(field.name)}
                    </div>
                  ))}
                </div>
                {selectedField && renderField(selectedField)}
              </div>
            );
          }

          return renderField(field);
        })}
      </div>
    </div>
  );
};
