import React, { ChangeEvent } from "react";
import AceEditor from "react-ace";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import "ace-builds/src-noconflict/mode-yaml"; // Import YAML mode
import "ace-builds/src-noconflict/mode-javascript"; // Import YAML mode
import "ace-builds/src-noconflict/mode-json"; // Import YAML mode
import "ace-builds/src-noconflict/mode-text"; // Import YAML mode
import "ace-builds/src-noconflict/theme-monokai"; // Import desired theme

import { removeTrailingNewlines } from "../utils/strings";

type CodeBlockProps = {
  mode?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  title?: string;
  wrap?: boolean;
};

export const CodeBlock = ({
  mode = "text",
  value = "",
  onChange = () => {},
  readOnly = false,
  title = "",
  wrap = false,
}: CodeBlockProps) => {
  const handleCopy = (): void => {
    navigator.clipboard.writeText(value);
  };

  const onChangeInternal = (value: string) => {
    onChange({ target: { value: removeTrailingNewlines(value) } } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="flex flex-col h-full w-full rounded-md overflow-hidden">
      <div className="flex items-center justify-between bg-[#2F3129] text-white p-2">
        <h2 className="text-sm mr-4">{title}</h2>
        <button className="text-white active:text-gray-300" onClick={handleCopy}>
          <FileCopyIcon />
        </button>
      </div>
      <AceEditor
        mode={mode}
        theme="monokai"
        value={`${value}\n\n`}
        onChange={onChangeInternal}
        name="code-editor"
        editorProps={{ $blockScrolling: true }}
        width="100%"
        height="100%"
        style={{
          flex: 1,
          overflow: "hidden",
        }}
        setOptions={{
          useWorker: false, // Disable worker for better performance with YAML
          tabSize: 2, // Set desired tab size
        }}
        readOnly={readOnly}
        wrapEnabled={wrap}
      />
    </div>
  );
}
