import { format } from "date-fns";
import clsx from "clsx";
import React from "react";

import { ChatMessageContent } from "./ChatMessageContent";
import { ThumbsDownIcon } from "./ThumbsDownIcon";
import { ThumbsUpIcon } from "./ThumbsUpIcon";

type ChatMessageProps = {
  role: string;
  content: string;
  type: "text" | "link" | "file";
  mode: string;
  retry?: number;
  isLoading?: boolean;
  timestamp?: string;
  hallucination: boolean | null;
  multipleMessages?: boolean;
  onClickThumbsDown?: () => void;
  onClickThumbsUp?: () => void;
};

export const ChatMessage = ({
  role = "assistant",
  content = "",
  type = "text",
  mode = "default",
  retry = 0,
  isLoading = false,
  timestamp = "",
  hallucination = false,
  multipleMessages = false,
  onClickThumbsDown = () => {},
  onClickThumbsUp = () => {},
}: ChatMessageProps) => {
  if (isLoading) {
    return (
      <div className="flex items-center my-2 py-5 px-3 rounded-lg bg-gray-200 text-gray-700 self-start">
        {retry > 0 && "Still thinking"}
        <div className="w-[5px] h-[5px] rounded-full bg-gray-400 mr-[3px] animate-pulse-bounce" />
        <div className="w-[5px] h-[5px] rounded-full bg-gray-400 mr-[3px] animate-pulse-bounce animate-delay-100" />
        <div className="w-[5px] h-[5px] rounded-full bg-gray-400 animate-pulse-bounce animate-delay-200" />
      </div>
    );
  }

  if (role === "system") {
    return (
      <div className="flex flex-row items-center">
        <div className="ml-8 flex-grow h-[2px] bg-green-500" />
        <div className="ml-2 mr-2 text-green-500">{content}</div>
        <div className="mr-8 flex-grow h-[2px] bg-green-500" />
      </div>
    );
  }

  const userMessageColor = mode === "coaching" ? "bg-green-500" : "bg-blue-500";
  const assistantMessageBorderColor = mode === "coaching" ? "border-[2px] border-green-100" : "";

  return (
    <div className={clsx(
      "relative w-full flex flex-col mt-1",
      !multipleMessages && "mb-2",
    )}>
      <div
        className={clsx(
          "group/item relative max-w-[300px] rounded-lg whitespace-pre-wrap break-words",
          type !== "link" && "py-2 px-3",
          role === "user" && `${userMessageColor} text-white self-end`,
          role === "assistant" && `${assistantMessageBorderColor} bg-gray-200 text-gray-700 self-start ml-4`,
          hallucination && "bg-red-100!",
        )}
      >
        <ChatMessageContent
          content={content}
          type={type}
        />
        {role === "assistant" && type === "text" && (
          <div className="absolute top-[-16px] right-[-36px] flex flex-row items-center justify-between h-2 p-4 bg-white rounded-full border-[1px] border-gray-200 shadow-md transition-all opacity-0 group-hover/item:opacity-100">
            <ThumbsDownIcon
              className="mr-4 text-red-600 hover:text-red-300 cursor-pointer"
              onClick={onClickThumbsDown}
            />
            <ThumbsUpIcon
              className="text-blue-600 hover:text-blue-300 cursor-pointer"
              onClick={onClickThumbsUp}
            />
          </div>
        )}
      </div>
      {!multipleMessages && (
        <div className={clsx(
          "text-xs text-black mt-1",
          role === "user" && "self-end mr-[2px]",
          role === "assistant" && "self-start ml-[30px]",
        )}>
          {format(new Date(timestamp), "h:mm:ss a")}
        </div>
      )}
      {!multipleMessages && role === "assistant" && (
        <img
          src="/logo.png"
          alt="logo"
          className="absolute w-8 bottom-[-4px] left-[-6px]"
        />
      )}
    </div>
  );
};
