import { AnyAction, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkDispatch } from "redux-thunk";

import { tasksSlice } from "./reducers/tasks";
import { useDispatch } from "react-redux";
import { configurationsSlice } from "./reducers/configurations";
import { personasSlice } from "./reducers/personas";
import { chatsSlice } from "./reducers/chats";
import { actionsSlice } from "./reducers/actions";
import { sessionReducer } from "./reducers/session";

export const store = configureStore({
  reducer: {
    actions: actionsSlice.reducer,
    chats: chatsSlice.reducer,
    configurations: configurationsSlice.reducer,
    personas: personasSlice.reducer,
    tasks: tasksSlice.reducer,
    session: sessionReducer,
  },
  middleware: [
    thunk,
  ],
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>

export const useAppDispatch = () => {
  return useDispatch() as AppDispatch;
};
