import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { ReactElement } from "react";

export const Dropdown = (
  props: React.PropsWithChildren<
    {
      items: Array<ReactElement>;
      itemAttributes?: React.HTMLAttributes<HTMLDivElement>;
      itemsContainerClassName?: string;
    } & React.HTMLAttributes<HTMLDivElement>
  >,
) => {
  const { items, children, className, itemsContainerClassName, ...rest } = props;

  return (
    <Menu as="div" className={clsx("inline-block text-left", className)} {...rest}>
      <Menu.Button as="div">{children}</Menu.Button>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            "absolute z-10 w-max max-w-[200px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black/[0.05] focus:outline-none",
            itemsContainerClassName,
          )}
        >
          <div className="py-1">
            {items &&
              items.map((item, index) => (
                <Menu.Item key={`${item.key ?? `backup-key-${index}`}`}>
                  {({ active }: { active: boolean }) => {
                    return React.cloneElement(item, {
                      className: clsx(
                        "group flex w-full items-center px-4 py-2 text-left text-sm font-normal",
                        active ? "min-w-0 bg-gray-100 text-gray-900" : "text-gray-700",
                        props.itemAttributes?.className,
                      ),
                    });
                  }}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
