import { Provider } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from "react";

import { ChatLogs } from "./pages/ChatLogs";
import { Debugger } from "./pages/Debugger";
import { Layout } from "./layouts/Layout";
import { NoMatch } from "./pages/NoMatch";
import { PoshieSdkProvider } from "./context/PoshieSdkProvider";
import { Settings } from "./pages/Settings";
import { SettingsActions } from "./pages/SettingsActions";
import { SettingsConfigurations } from "./pages/SettingsConfigurations";
import { SettingsLayout } from "./layouts/SettingsLayout";
import { SettingsPeronas } from "./pages/SettingsPersonas";
import { SettingsPreview } from "./pages/SettingsPreview";
import { store } from "./redux/store";
import { Tasks } from "./pages/Tasks";
import { Preview } from "./pages/Preview";

export const App = () => {
  return (
    <Provider store={store}>
      <PoshieSdkProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Debugger />} />
              <Route path="chatlogs" element={<ChatLogs />} />
              <Route path="settings" element={<SettingsLayout />}>
                <Route path="" element={<Settings />} />
                <Route path="configurations" element={<SettingsConfigurations />} />
                <Route path="personas" element={<SettingsPeronas />} />
                <Route path="actions" element={<SettingsActions />} />
                <Route path="preview" element={<SettingsPreview />} />
                <Route path="*" element={<NoMatch />} />
              </Route>
              <Route path="tasks" element={<Tasks />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
            <Route path="preview" element={<Preview />} />
          </Routes>
        </BrowserRouter>
      </PoshieSdkProvider>
    </Provider>
  );
}
