import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Settings = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/settings/configurations");
  });

  return (
    <div>Not Found!</div>
  );
};
