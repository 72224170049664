import { getRestActions } from "../utils/RestActions";
import { RestEntity, getRestSlice } from "../utils/RestSlice";

export interface Configuration extends RestEntity {
  id: string;
  name: string;
  url: string;
  knowledge: string;
  data: string;
  enabled: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export const configurationsSlice = getRestSlice<Configuration>("configurations");
export const configurationsActions = getRestActions<Configuration>("configurations", configurationsSlice);
