import React from "react";
import clsx from "clsx";

interface ToggleProps {
  className?: string;
  label?: string;
  onToggle?: (on: boolean) => void;
  value?: boolean;
  options?: string[];
}

export const Toggle = ({
  className,
  label,
  onToggle = () => {},
  value = false,
  options = [],
}: ToggleProps) => {
  const handleToggle = () => {
    onToggle(!value);
  };

  return (
    <div className="flex items-center h-8">
      {label && (
        <div className="whitespace-nowrap mr-2">{label}</div>
      )}
      <button
        className={clsx(
          "relative flex items-center rounded-full border border-gray-200 p-1 w-[80px] transition-all",
          value ? "bg-green-500" : "bg-gray-100",
          className,
        )}
        onClick={handleToggle}
        aria-pressed={value}
      >
        <div className={clsx(
          "absolute transition-all left-[15px] text-xs z-0",
          value ? "text-white" : "text-gray-100"
        )}>{options[0]}</div>
        <div
          className={clsx(
            "z-10 w-8 h-8 rounded-full bg-white shadow-md transform transition-all ease-in-out duration-300",
            value ? "translate-x-[38px]" : ""
          )}
        />
        <div className={clsx(
          "absolute transition-all right-[15px] text-xs z-0",
          value ? "text-green-500" : "text-gray-600"
        )}>{options[1]}</div>
      </button>
    </div>
  );
};
