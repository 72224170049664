import { AnyAction, EntityId } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import { CONCIERGE_SERVICE_URL } from "../../config";
import { AppDispatch, RootState } from "../store";
import { RestEntity, RestSlice } from "./RestSlice";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export type RequestOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: Record<string, unknown>;
};

export type GetResponse<T> = {
  data: T[];
  metadata?: Record<string, string>;
};

export type CreateResponse<T> = {
  data: T;
  metadata?: Record<string, string>;
};

export type GetByIdResponse<T> = {
  data: T;
  metadata?: Record<string, string>;
};

export type UpdateByIdResponse<T> = {
  data: T;
  metadata?: Record<string, string>;
};

export type DeleteByIdResponse<T> = {
  data: T;
  metadata?: Record<string, string>;
};

export const makeRequest = async <T>(endpoint: string, options: RequestOptions = {}): Promise<T> => {
  const response = await fetch(`${CONCIERGE_SERVICE_URL}${endpoint}`, {
    ...options,
    body: options.body ? JSON.stringify(options.body) : null,
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json() as T;
};

export const getRestActions = <T extends RestEntity>(name: string, slice: RestSlice<T>, additionalActions = {}) => {
  const get = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.isLoading());

    const response = await makeRequest<GetResponse<T>>(`/v1/${name}`);

    dispatch(slice.actions.setAll(response.data));
  };

  const create = (entity: Partial<T>) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.isLoadingWrite());

    const response = await makeRequest<CreateResponse<T>>(`/v1/${name}`, {
      method: "POST",
      body: entity,
    });

    dispatch(slice.actions.addOne(response.data));

    return response.data;
  };

  const getById = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.isLoading());

    const response = await makeRequest<GetByIdResponse<T>>(`/v1/${name}/${id}`);

    dispatch(slice.actions.addOne(response.data));
  };

  const updateById = (id: EntityId, entity: Partial<T>, options = { remote: true }) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.isLoadingWrite());

    if (options.remote) {
      const response = await makeRequest<UpdateByIdResponse<T>>(`/v1/${name}/${id}`, {
        method: "PATCH",
        body: entity,
      });

      dispatch(slice.actions.updateOne({ id, changes: response.data }));
    } else {
      dispatch(slice.actions.updateOne({ id, changes: entity }));
    }
  };

  const deleteById = (id: EntityId, options = { remote: true }) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.isLoadingDelete());

    if (options.remote) {
      await makeRequest<DeleteByIdResponse<T>>(`/v1/${name}/${id}`, {
        method: "DELETE",
      });

      dispatch(slice.actions.removeOne(id));
    } else {
      dispatch(slice.actions.removeOne(id));
    }
  };

  return {
    ...additionalActions,
    get,
    create,
    getById,
    updateById,
    deleteById,
  };
};

export type RestActions<T extends RestEntity> = ReturnType<typeof getRestActions<T>>;
