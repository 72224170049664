import React from "react";

export const ChevronDownIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M0.248051 3.97838C-0.0951188 4.2985 -0.07846 4.89874 0.274705 5.23553L7.38179 12.0582C7.55837 12.2249 7.81158 12.305 8.00153 12.305C8.18478 12.305 8.41133 12.2249 8.65126 12.0248L15.7217 5.23553C16.0848 4.89874 16.0915 4.2985 15.7483 3.98507C15.4151 3.62158 14.8487 3.61491 14.4955 3.95837L8.00153 10.1841L1.50087 3.9517C1.33095 3.78832 1.10772 3.70496 0.887785 3.70496C0.657895 3.70496 0.418009 3.79499 0.248051 3.97838Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
