import React from "react";
import { FileIcon } from "./FileIcon";
import { DownloadIcon } from "./DownloadIcon";

type ChatMessageContentProps = {
  content: string;
  type: "text" | "link" | "file";
};

export const ChatMessageContent = ({
  content = "",
  type = "text",
}: ChatMessageContentProps) => {
  if (type === "file") {
    return (
      <div className="relative bg-white p-4 m-2 rounded-lg cursor-pointer">
        <FileIcon className="h-8 w-8"/>
        <div className="absolute bottom-[-8px] right-[-8px] h-6 w-6 flex items-center justify-center rounded-full bg-blue-500">
          <DownloadIcon className="h-3 w-3 text-white"/>
        </div>
      </div>
    );
  }

  if (type === "link") {
    return (
      <div className="relative rounded-lg cursor-pointer overflow-hidden">
        <a href={content} target="_blank" rel="noreferrer">
          <img
            src={`https://api.microlink.io/?url=${content}&screenshot=true&meta=false&embed=screenshot.url`}
            alt={content}
            className="max-h-[100px] w-full object-cover object-top"
          />
          <div className="py-2 px-3 truncate font-semibold">{content}</div>
        </a>
      </div>
    )
  }

  content = content.replace(/\[(.*?)\]\((.*?)\)/g, "<a href='$2' class='text-blue-500 underline' target='_blank'>$1</a>");
  content = content.replace(/\\n/g, "<br />");

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
};
