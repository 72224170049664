import React from "react";

export const FileIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M2 2.19064L2.00005 4.25988C2.00005 4.73071 2.00225 6.81986 2.00447 8.92356C2.00672 11.0568 2.00899 13.2049 2.00899 13.6958C2.00899 14.6709 2.89828 15.4967 3.8313 15.469C4.77724 15.4862 11.2069 15.5165 12.1681 15.4888C13.1342 15.4748 13.9876 14.6786 13.9938 13.7197C14 12.7609 14 4.4859 14 4.07533C14 3.66487 13.8286 3.37443 13.4227 2.87696C13.0167 2.37949 12.1968 1.63282 11.6545 1.18609C11.1121 0.739583 10.7196 0.545318 10.2357 0.545318C10.037 0.545318 9.08776 0.53767 7.96919 0.528659C6.36476 0.515733 4.41188 0.5 3.82536 0.5C2.83013 0.5 2 1.1862 2 2.19064ZM9.7691 2.30987C9.7691 2.05989 9.90999 1.99095 10.0839 2.15482L12.2573 4.20427C12.4311 4.36814 12.3855 4.50002 12.1555 4.49878L9.97752 4.48737C9.8624 4.48669 9.7691 4.38487 9.7691 4.25988V2.30987ZM4.09999 2.07812H7.90004C8.12096 2.07812 8.30004 2.25721 8.30004 2.47812V5.5C8.30004 5.77614 8.5239 6 8.80004 6H11.9607C12.1816 6 12.3607 6.17909 12.3607 6.4V13.4C12.3607 13.6209 12.1816 13.8 11.9607 13.8H4.09999C3.87908 13.8 3.69999 13.6209 3.69999 13.4V2.47813C3.69999 2.25721 3.87908 2.07812 4.09999 2.07812Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
