import React from 'react';

import { ChatMessageList } from './ChatMessageList';
import { ChatInput } from './ChatInput';
import { Message } from '../redux/reducers/chats';
import clsx from 'clsx';

type ChatConsoleProps = {
  className?: string;
  messages?: Message[];
  mode?: string;
  onMessageSend?: (message: string) => void;
  onToggleCoachingMode?: (value: boolean) => void;
  showCoachingMode?: boolean;
  isLoading?: boolean;
  retry?: number;
};

export const ChatConsole = ({
  className = "",
  messages = [],
  mode = "default",
  onMessageSend = () => {},
  onToggleCoachingMode = () => {},
  showCoachingMode = false,
  isLoading = false,
  retry = 0,
}: ChatConsoleProps) => {
  return (
    <div className={clsx("flex flex-col flex-grow h-full bg-white overflow-hidden", className)}>
      <ChatMessageList
        messages={messages}
        isLoading={isLoading}
        mode={mode}
        retry={retry}
      />
      <ChatInput
        mode={mode}
        onMessageSend={onMessageSend}
        onToggleCoachingMode={onToggleCoachingMode}
        showCoachingMode={showCoachingMode}
      />
    </div>
  );
};
