import React from "react";

export const LinkIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M9.88137 2.65283L8.43624 4.07314C8.79699 4.25799 9.13288 4.48345 9.43722 4.76527L10.7065 3.496C11.1732 3.02933 11.7864 2.79485 12.3996 2.79485C13.0196 2.79485 13.6328 3.02933 14.0995 3.496C15.0351 4.43386 15.0351 5.95561 14.0995 6.89349L11.2701 9.72058C10.3661 10.6246 8.78797 10.6246 7.87716 9.72058C7.42625 9.26518 7.17601 8.6655 7.17601 8.02296C7.17601 7.5856 7.30226 7.16401 7.51867 6.80104C7.23688 6.54179 6.84908 6.38847 6.43429 6.38847C6.41625 6.38847 6.39596 6.39523 6.38244 6.39523C6.12544 6.89349 5.9721 7.44583 5.9721 8.02296C5.9721 8.98336 6.35088 9.88743 7.02948 10.566C7.70804 11.2446 8.61208 11.6234 9.57253 11.6234C10.5352 11.6234 11.4415 11.2446 12.1201 10.566L14.9427 7.73666C15.6258 7.04002 15.9775 6.11795 16 5.19362C16 4.27152 15.6258 3.35395 14.9472 2.65283C14.2641 1.96971 13.3735 1.61801 12.4785 1.59998C12.456 1.59998 12.4289 1.59998 12.3996 1.59998C11.4821 1.59998 10.56 1.94942 9.88137 2.65283ZM3.88222 5.40102L1.05288 8.25293C0.351728 8.95631 0 9.87841 0 10.796C0 11.7203 0.351728 12.6424 1.05288 13.3435C1.70889 13.9951 2.54755 14.3445 3.402 14.3918C4.3895 14.446 5.39499 14.0965 6.14799 13.3435L7.56376 11.9232C7.20532 11.7383 6.86712 11.5106 6.56729 11.2266L5.29805 12.4711C4.82684 12.9422 4.21361 13.1722 3.60043 13.1722C2.9872 13.1722 2.37397 12.9422 1.90281 12.4711C0.96496 11.5399 0.96496 10.0159 1.90281 9.08033L4.72991 6.25095C5.6362 5.34691 7.21885 5.34691 8.12289 6.25095C8.58052 6.7041 8.82855 7.30831 8.82855 7.94857C8.82855 8.38819 8.70456 8.80302 8.48358 9.17275C8.76317 9.42975 9.15542 9.58307 9.57253 9.58307C9.58605 9.58307 9.60409 9.57856 9.62212 9.57856C9.87686 9.07582 10.0279 8.52571 10.0279 7.94857C10.0279 6.99043 9.64918 6.08413 8.97282 5.40102C8.29422 4.72469 7.38792 4.35045 6.42978 4.35045C5.46487 4.35045 4.56083 4.72469 3.88222 5.40102Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
