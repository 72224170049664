import { CircularProgress } from "@mui/material";
import React, { useContext } from "react";

import { CodeBlock } from "../components/CodeBlock";
import { ChatConsole } from "../components/ChatConsole";
import { PoshieSdkContext } from "../context/PoshieSdkProvider";
import { ChatMetadata } from "../components/ChatMetadata";

export const Debugger = () => {
  const { sdk, configurationId, chat, retry, isLoading } = useContext(PoshieSdkContext);

  const onMessageSend = async (message: string) => {
    await sdk.sendMessage(message);
  };

  const onClickRefresh = async () => {
    await sdk.createChat();
  };

  const onChangeConfigurationId = async (value: string) => {
    await sdk.setConfigurationId(value);
  };

  const onToggleCoachingMode = (value: boolean) => {
    window.alert("Not implemented");
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className="flex flex-row w-full h-full overflow-hidden p-4">
        <div className="flex h-full w-[40%] mr-4 border border-gray-200 rounded-lg overflow-hidden">
          <ChatConsole
            messages={chat?.messages}
            mode="default"
            onToggleCoachingMode={onToggleCoachingMode}
            onMessageSend={onMessageSend}
            isLoading={isLoading}
            retry={retry}
          />
        </div>
        <div className="flex flex-col h-full w-[60%] items-end">
          <ChatMetadata
            values={{
              "Session ID": sdk.chatId || "Unknown",
              "Message Count": String(chat?.messages.length),
            }}
            information={chat?.metadata?.information as string}
            configurationId={configurationId || ""}
            onChangeConfigurationId={onChangeConfigurationId}
            onClickRefresh={onClickRefresh}
          />
          {isLoading ? (
            <div className="flex flex-col w-full h-full justify-center items-center">
              <CircularProgress
                size={24}
                color="info"
              />
            </div>
          ) : (
            <CodeBlock
              title="Request Metadata"
              value={JSON.stringify(chat?.messages, null, 2)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
