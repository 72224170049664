import { getRestActions } from "../utils/RestActions";
import { RestEntity, getRestSlice } from "../utils/RestSlice";

export interface Action extends RestEntity {
  id: string;
  name: string;
  instructions: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export const actionsSlice = getRestSlice<Action>("actions");
export const actionsActions = getRestActions<Action>("actions", actionsSlice);
