import React from "react";

export const ChevronRightIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M3.98019 0.247602C3.62216 0.582192 3.60878 1.151 3.95008 1.50567L10.1969 7.96327L3.95008 14.4845C3.60878 14.8391 3.61547 15.4079 3.98019 15.7526C4.33483 16.0939 4.89695 16.0805 5.23825 15.7224L12.0472 8.61573C12.2178 8.4451 12.3015 8.21757 12.3015 8.00008C12.3015 7.77255 12.2178 7.55508 12.0472 7.38108L5.23825 0.274368C5.06089 0.0869884 4.82669 0 4.59584 0C4.37498 0 4.15082 0.080297 3.98019 0.247602Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
