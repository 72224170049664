import clsx from "clsx";
import React, { CSSProperties, MouseEvent, ReactElement } from "react";

import { Loader } from "./Loader";

export function tw(strings: TemplateStringsArray): string {
  return strings.join("");
}

export type ButtonColorProp = "dark-blue" | "purple" | "blue" | "red" | "black";

type ButtonSizeProp = "large" | "small";

export type ButtonVariantProp = "solid" | "border" | "text";

type ButtonProps = {
  className?: string;
  color?: ButtonColorProp;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => unknown;
  size?: ButtonSizeProp;
  style?: CSSProperties;
  variant?: ButtonVariantProp;
} & React.HTMLAttributes<HTMLButtonElement>;

const buttonVariantColor: Record<ButtonVariantProp, Record<ButtonColorProp | "all", string>> = {
  border: {
    all: tw`bg-white ring-offset-1 ring-offset-white focus-visible:ring`,

    "dark-blue": tw`border-dark-blue-700 text-dark-blue-700 ring-dark-blue-700 aria-disabled:border-dark-blue-200 aria-disabled:text-dark-blue-200 [&:not([aria-disabled='true'])]:hover:bg-dark-blue-700/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-dark-blue-700/[0.05] [&:not([aria-disabled='true'])]:active:bg-dark-blue-700/[0.10]`,
    blue: tw`border-blue-700 text-blue-700 ring-blue-700 aria-disabled:border-blue-200 aria-disabled:text-blue-200 [&:not([aria-disabled='true'])]:hover:bg-blue-700/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-blue-700/[0.05] [&:not([aria-disabled='true'])]:active:bg-blue-700/[0.10]`,
    red: tw`border-red-600 text-red-600 ring-red-600 aria-disabled:border-red-200 aria-disabled:text-red-200 [&:not([aria-disabled='true'])]:hover:bg-red-600/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-red-600/[0.05] [&:not([aria-disabled='true'])]:active:bg-red-600/[0.10]`,
    purple: tw`border-purple-600 text-purple-600 ring-purple-600 aria-disabled:border-purple-200 aria-disabled:text-purple-200 [&:not([aria-disabled='true'])]:hover:bg-purple-600/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-purple-600/[0.05] [&:not([aria-disabled='true'])]:active:bg-purple-600/[0.10]`,
    black: tw`border-gray-200 text-gray-900 ring-gray-500 focus-visible:ring aria-disabled:border-gray-200 aria-disabled:text-gray-200 [&:not([aria-disabled='true'])]:hover:bg-gray-50 [&:not([aria-disabled='true'])]:focus-visible:border-gray-500 [&:not([aria-disabled='true'])]:focus-visible:bg-gray-50 [&:not([aria-disabled='true'])]:active:bg-gray-100`,
  },

  solid: {
    all: tw`text-white ring-offset-1 ring-offset-white focus-visible:ring`,

    "dark-blue": tw`border-dark-blue-700 bg-dark-blue-700 ring-dark-blue-800 hover:border-dark-blue-800 hover:bg-dark-blue-800 focus-visible:border-dark-blue-800 focus-visible:bg-dark-blue-800 active:bg-dark-blue-900 aria-disabled:border-dark-blue-200 aria-disabled:bg-dark-blue-200`,
    blue: tw`border-blue-700 bg-blue-700 ring-blue-800 hover:border-blue-800 hover:bg-blue-800 focus-visible:border-blue-800 focus-visible:bg-blue-800 active:bg-blue-900 aria-disabled:border-blue-200 aria-disabled:bg-blue-200`,
    red: tw`border-red-600 bg-red-600 ring-red-700 hover:border-red-700 hover:bg-red-700 focus-visible:border-red-700 focus-visible:bg-red-700 active:bg-red-800 aria-disabled:border-red-200 aria-disabled:bg-red-200`,
    purple: tw`border-purple-600 bg-purple-600 ring-purple-700 hover:border-purple-700 hover:bg-purple-700 focus-visible:border-purple-700 focus-visible:bg-purple-700 active:bg-purple-800 aria-disabled:border-purple-200 aria-disabled:bg-purple-200`,
    black: tw`border-gray-900 bg-gray-900 ring-gray-900 hover:border-gray-700 hover:bg-gray-700 focus-visible:border-gray-700 focus-visible:bg-gray-700 active:bg-gray-800 aria-disabled:border-gray-200 aria-disabled:bg-gray-200`,
  },

  text: {
    all: tw`border-transparent bg-transparent ring-offset-1 ring-offset-transparent focus-visible:ring`,

    "dark-blue": tw`text-dark-blue-700 ring-dark-blue-700  aria-disabled:text-dark-blue-200 [&:not([aria-disabled='true'])]:hover:bg-dark-blue-800/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-dark-blue-800/[0.05] [&:not([aria-disabled='true'])]:active:bg-dark-blue-800/[0.10]`,
    blue: tw`text-blue-700 ring-blue-700 aria-disabled:text-blue-200 [&:not([aria-disabled='true'])]:hover:bg-blue-700/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-blue-700/[0.05] [&:not([aria-disabled='true'])]:active:bg-blue-700/[0.10]`,
    red: tw`text-red-600 ring-red-600 aria-disabled:text-red-200 [&:not([aria-disabled='true'])]:hover:bg-red-600/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-red-600/[0.05] [&:not([aria-disabled='true'])]:active:bg-red-600/[0.10]`,
    purple: tw`text-purple-600 ring-purple-600 aria-disabled:text-purple-200 [&:not([aria-disabled='true'])]:hover:bg-purple-600/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-purple-600/[0.05] [&:not([aria-disabled='true'])]:active:bg-purple-600/[0.10]`,
    black: tw`text-gray-900 ring-gray-900 aria-disabled:text-gray-200 [&:not([aria-disabled='true'])]:hover:bg-gray-900/[0.05] [&:not([aria-disabled='true'])]:focus-visible:bg-gray-900/[0.05] [&:not([aria-disabled='true'])]:active:bg-gray-900/[0.10]`,
  },
};

const buttonSizeStyles: Record<ButtonSizeProp, string> = {
  large: tw`px-4 py-2 text-base`,
  small: tw`px-3 py-1.5 text-sm`,
};

export const Button = (props: ButtonProps) => {
  const {
    color: colorProp,
    children,
    className,
    isDisabled: isDisabledProp = false,
    isLoading = false,
    iconLeft,
    iconRight,
    onClick,
    size = "large",
    style,
    variant = "solid",
    ...rest
  } = props;
  const isDisabled = isLoading || isDisabledProp;

  const color = variant === "border" && !colorProp ? "black" : colorProp ?? "blue";

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (isDisabled) return;

    if (onClick) onClick(event);
  };

  return (
    <button
      className={clsx(
        "select-none rounded-lg border text-center font-semibold outline-none aria-disabled:cursor-not-allowed",
        isLoading ? "relative" : "",
        buttonVariantColor[variant]["all"],
        buttonVariantColor[variant][color],
        className,
      )}
      aria-disabled={Boolean(isDisabled || !onClick)}
      data-testid="button"
      style={style}
      type="button"
      onClick={onClickHandler}
      {...rest}
    >
      {isLoading && (
        <span aria-hidden="true" className="absolute inset-0 m-auto h-fit w-fit text-center">
          <Loader color="black" />
        </span>
      )}
      <span className={clsx("flex items-center justify-center", isLoading ? "opacity-0" : "", buttonSizeStyles[size])}>
        {iconLeft && React.cloneElement(iconLeft, { className: `h-4 w-4 ${children ? "mr-3" : ""}`, "aria-hidden": true })}

        {children}

        {iconRight && React.cloneElement(iconRight, { className: `h-4 w-4  ${children ? "ml-3" : ""}`, "aria-hidden": true })}
      </span>
    </button>
  );
};
