import React from "react";

export const ErrorFilledIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.8002 4H7.2002V9.6H8.8002V4ZM8.8002 10.4H7.2002V12H8.8002V10.4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
