import { AnyAction } from "@reduxjs/toolkit";

export const SET_SESSION = 'SET_SESSION';

export type Session = {
  email: string;
  name: string;
  picture: string;
};

export const setSession = (session: Session) => ({
  type: SET_SESSION,
  payload: session,
});

export const sessionReducer = (state: { data: Session | null } = { data: null }, action: AnyAction) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
