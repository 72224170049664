import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Toggle } from './Toggle';

type ChatInputProps = {
  mode?: string;
  onMessageSend?: (message: string) => void;
  onToggleCoachingMode?: (value: boolean) => void;
  showCoachingMode?: boolean;
};

export const ChatInput = ({
  mode = "default",
  onMessageSend = () => {},
  onToggleCoachingMode = () => {},
  showCoachingMode = false,
}: ChatInputProps) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState<string>('');

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);

    if (textareaRef.current) {
      if (event.target.value === "") {
        textareaRef.current.style.height = "42px";
      } else {
        textareaRef.current.style.height = `${textareaRef.current?.scrollHeight - (textareaRef.current?.scrollHeight - 40) % 24}px`;
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
    }
  };

  const onClickSend = useCallback(() => {
    onMessageSend(message);
    setMessage("");

    if (textareaRef.current) {
      textareaRef.current.style.height = "42px";
    }
  }, [message, onMessageSend]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onClickSend();
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [onClickSend]);

  return (
    <div className="flex flex-row items-center p-4 border-t border-gray-200">
      <textarea
        ref={textareaRef}
        className="flex flex-grow h-auto max-h-[112px] min-h-[42px] px-3 py-2 rounded-3xl border border-gray-200 bg-white text-gray-700 mr-2 resize-none focus:outline-none scrollbar-hidden"
        placeholder="Type your message..."
        value={message}
        onChange={onChangeInput}
        rows={1}
      />
      <button
        className={clsx(
          "flex-none px-4 py-2 rounded-3xl h-full min-h-[42px] text-white font-bold focus:outline-none focus:ring-2 transition-all",
          mode === "coaching" ? "bg-green-500 focus:ring-green-500" : "bg-blue-500 focus:ring-blue-500",
        )}
        onClick={onClickSend}
      >
        Send
      </button>
      {showCoachingMode && (
        <Toggle
          className="ml-2"
          onToggle={onToggleCoachingMode}
          value={mode === "coaching"}
        />
      )}
    </div>
  );
};
